<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/empresas" />
      </v-col>
    </v-row>

    <empresas-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario="validacaoFormulario"
      :empresa="empresa"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import empresas from '@/api/empresas'

  export default {
    components: {
      EmpresasCampos: () => import('./EmpresasCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        validacaoFormulario: {},
        empresa: {},
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await empresas.buscar(this.$route.params.id)

          const empresa = resposta.data
          empresa.imagem_logo = empresa.imagem
          delete empresa.imagem

          this.empresa = empresa
        } catch (e) {
          this.$router.push('/empresas', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (empresa) {
        this.carregandoSalvar = true

        try {
          delete empresa.endereco_id
          delete empresa.endereco
          delete empresa.imagem_logo

          const formData = new FormData()
          formData.append('_method', 'put')
          Object.entries(empresa).forEach(([campo, valor]) => {
            formData.append(campo, valor)
          })

          await empresas.atualizar(empresa.id, formData)

          this.carregandoSalvar = false

          this.$router.push('/empresas', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = e.response.data.errors
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoSalvar = false
        }
      },
    },

  }
</script>
